import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import "./productCard.css";

function ProductCard(props) {
  const productArray = props.product;
  const [currentIndex2, setCurrentIndex2] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleNext = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentIndex2((prevIndex) => (prevIndex + 1) % productArray.length);
      setIsTransitioning(false);
    }, 500); // Transition duration in milliseconds
  };

  const handleSwipedLeft = () => {
    setCurrentIndex2((prevIndex) => (prevIndex + 1) % productArray.length);
  };

  const handleSwipedRight = () => {
    setCurrentIndex2(
      (prevIndex) => (prevIndex - 1 + productArray.length) % productArray.length
    );
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleSwipedLeft,
    onSwipedRight: handleSwipedRight,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });
  useEffect(() => {
    setCurrentIndex2(0);
  }, [productArray]);

  const currentProduct = productArray[currentIndex2];

  return (
    <div {...handlers} className="productCard">
      <div
        className=" col-6 col-lg-6 col-md-6 product_img"
        style={{
          backgroundImage: `url(${currentProduct.image})`,
        }}
      ></div>
      <div className="description col-5 col-lg-5 col-md-5">
        <div className="">
          <div className="pdt_name">
            <h1>{currentProduct.name}</h1>
          </div>
          <div className="pdt_desc">
            <h3 style={{}}>{currentProduct.description}</h3>
          </div>
          <div className="pdt_ingredients"></div>
        </div>
      </div>
      <div className="nextIcon col-1 col-lg-1 col-md-1 ">
        <span onClick={handleNext} className="move_right">
          <i class="fa-solid fa-chevron-right"></i>
        </span>
      </div>
      <div className="dots">
        {productArray.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex2 ? "active" : ""}`}
            onClick={() => setCurrentIndex2(index)}
          ></span>
        ))}
      </div>
    </div>
  );
}

export default ProductCard;
