import React, { useEffect, useState } from "react";
import "./Navbar.css";
import Logo from "../static/halle_logo_2.png";
function Navbar() {

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setIsExpanded(false);
    }
  };

  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    setScrolled(window.scrollY > 200); // Adjust this value as needed
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };


  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light  ${
        scrolled ? "scrolled" : ""
      }`}
    >
      <div className="container-fluid">
        <img src={Logo} alt="Logo" className="logo" />
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          onClick={handleToggle}
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isExpanded ? 'show' : ''}`} id="navbarNav">
          <ul className="navbar-nav ms-auto ">
            <li className="nav-item">
              <a href="#home" className="nav-anchor" onClick={() => scrollToSection('home')}>
                Home
              </a>
            </li>
            <li className="nav-item"  >
              <a href="#products" className="nav-anchor" onClick={() => scrollToSection('product')}>
                Products
              </a>
            </li>

            <li className="nav-item">
              <a href="#about_us" className="nav-anchor" onClick={() => scrollToSection('about_us')}>
                About us
              </a>
            </li>
            <li className="nav-item" >
              <a href="#contact_us" className="nav-anchor" onClick={() => scrollToSection('contact_us')}>
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
