import React from "react";
import "./contactus.css";
import ContactUsImg from  '../static/contact_us.jpg'

function ContactUs() {
  return (
    <div id="contact_us" className="contact-us" style={{
      backgroundImage: `url(${ContactUsImg})`,
    }}>
      <div className="heading">
        <h1 className="title">Join Halle Today!</h1>
        <p className='contact_desc'>
        We invite you to savor the essence of Halle. Reach out to us for business queries.
        </p>
      </div>

      <div className="contact-info">
        <div className="contact-item">
          <i className="fas fa-phone"
          style={{
            "color" :"#28a745"
          }}></i>
          <div className="contact_text">
            <span>Call us on</span>
            <p>
              <a className="link" href="tel:7598670085">75986 70085</a>
            </p>
          </div>
        </div>
        <div className="contact-item">
          <i className="fas fa-envelope"
          style={{
            "color" :"#1877F2"
          }}></i>
          <div className="contact_text">
            <span>Write a mail </span>
            <p>
              <a className="link" href="mailto:relations@halle.in">relations@halle.in</a>
            </p>
          </div>
        </div>

        <div className="contact_item socials">
          <span style={{color:"white"}}>Follow us</span>
          <div className="social-media"> 
            {/* <p>
              <a
                href="https://facebook.com/yourprofile"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook"  style={{
                  "color" :"#1877F2"
                }}></i>
              </a>
            </p>
            <p>
              <a
                href="https://linkedin.com/in/yourprofile"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin"  style={{
                  "color" :"#0077B5"
                }}></i>
              </a>
            </p> */}
            <p>
              <a
              href={`https://wa.me/7598670085`}
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp-link">
                  <i className="fab fa-whatsapp" style={{
                  "color" :"##25D366"
                }}></i>
              </a>
            </p>
            <p>
              <a
                href="https://www.instagram.com/hallechocolates?igsh=MTlkMTN5bzJhZHBwbQ=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram" style={{
                  "color" :"#C13584"
                }}></i>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
