import React from "react";
import image2 from "../static/about_us.jpg";
import './aboutUs.css'

function AboutUs() {
  return (
    <div id="about_us" className="about_us">
      <div
        className="about_img "
        style={{
          backgroundImage: `url(${image2})`,
        }}
      ></div>
      <div className="about_description">
        <h3 className="about_us_text">
          <p>Nestled amidst the lush landscapes of Pollachi, Tamil Nadu, Halle is more than just a chocolate brand. It's a testament to the harmonious blend of science and nature. Halle's cacao story begins in the heart of nature. Our farm, nestled near the Parambikulam Tiger Reserve, is a thriving ecosystem where cacao trees coexist with an array of other crops. We embrace sustainable farming practices, nurturing our land without harming the delicate balance of wildlife. From the moment our cacao pods ripen on the trees, we meticulously oversee every step of the process. Our state-of-the-art facilities allow us to control quality from bean to bar, ensuring that each bite is a symphony of flavor and texture.</p>
        </h3>
      </div>
    </div>
  );
}

export default AboutUs;
