import Navbar from "./Navbar/Navbar";
import "./App.css";
import Home from "./Home/Home";
import Products from "./Products/Products";
import ContactUs from "./Contact/ContactUs";
import AboutUs from "./AboutUs/AboutUs";
import { useEffect } from "react";


function App() {

  
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on reload
  }, []);
  return (
    <div className="App">
      <Navbar />
      <Home />
      <Products />
      <AboutUs />
      <ContactUs />
    </div>
  );
}

export default App;
