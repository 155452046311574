
import React, { useEffect, useState } from 'react'
import './Home.css'
import Image1 from '../static/Landing_1.jpg'
import Image2 from '../static/Landing_2.jpg'
import Image3 from '../static/Landing_3.jpg'

const images = [Image1, Image2, Image3]
const msgArr = ["Halle: From Bean to Bar, ethically sourced chocolate goodness.", "Indulge in Halle: Where flavor meets artistry.", "Elevate your taste buds with Halle's unique chocolate flavors."]

function Home() {

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        
            const interval = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
            }, 4000); // Change slide every 4 seconds

            return () => clearInterval(interval);
        
    }, []);
    return (
        <div 
        id='home'
            className="carousel-container bg-light "
        >
            {msgArr.map((msg, index) => (
                <div key={index} className={`landing-text carousel-item ${index === currentIndex ? 'active' : ''} `}>
                <h1>{msg}</h1>
            </div>
           ))}
          
            <div className=''>
                <div id="carouselExample" className="carousel col-md-12 " data-bs-ride="carousel">
                    <div className="carousel-inner">
                        {images.map((image, index) => (
                            <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''} mx-auto`}>
                                <div
                                    // onMouseEnter={() => setIsPaused(true)}
                                    // onMouseLeave={() => setIsPaused(false)}
                                    src={image} className="img-fluid"
                                    alt={`Slide ${index + 1}`}
                                    style={{       
                                        backgroundImage: `url(${image})`,
                                
                                    }} >

                                    <div className="gradient-overlay"></div>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="carousel-dots">
                {images.map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${index === currentIndex ? 'active' : ''}`}
                        onClick={() => setCurrentIndex(index)}
                    ></span>
                ))}
            </div>
        </div>
    );

}

export default Home
