import Cocoa1 from './static/Product_11.png'
import Cocoa_butter from './static/cocoa_butter.png';
import Cocoa_mass from './static/cocoa_mass.png';
import cocoa_powder from './static/cocoa_powder.png';
import dark_compound from './static/dark_compound.png';
import milk_compound from './static/milk_compound.png';

const productData = [
    {
      category: 'Coverture',
      products: [
        { id: 1, name: '45% Dark Chocolate',   image : Cocoa1 },
        { id: 2, name: '55% Dark Chocolate',  image : Cocoa1},
        { id: 3, name: '70% Dark Chocolate', image : Cocoa1},
      ],
    },
    {
      category: 'Compound',
      products: [
        { id: 4, name: 'Dark Compound', image : dark_compound },
        { id: 5, name: 'Milk Compound', image : milk_compound },
        { id: 6, name: 'White Compound', image : dark_compound }
      ],
    },
    {
      category: 'Cocoa extracts ',
      products: [
        { id: 1, name: 'Natural Cocoa Powder',  image : cocoa_powder },
        { id: 2, name: 'Natural Cocoa Butter', image : Cocoa_butter },
        { id: 3, name: 'Cocoa Mass',  image : Cocoa_mass  },
      ],
    },
  ];
  
  export default productData;