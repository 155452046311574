import React, { useState } from "react";
import "./products.css";
import ProductCard from "../ProductCard/ProductCard";
import productData from "../Products";

function Products() {
  const [selectedType, setSelectedType] = useState(productData[0]);

  const handleProductClick = (type) => {
    setSelectedType(type);
  };
  console.log(selectedType);

  return (
    <div id="products" className="container1">
      <div className="product_types ">
      <h1 className="product_header">Our Products</h1>
        <div className="product_types_map">
    
          {productData.map((product, index) => (
            <React.Fragment key={index}>
              <span
                className={`product-item ${
                  selectedType === product ? "active" : ""
                }`}
                onClick={() => handleProductClick(product)}
                style={{ cursor: "pointer" }}
              >
                {product.category}
              </span>
              {index < productData.length - 1 && (
                <span className="mx-2 product-separator ">|</span>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div>
        <ProductCard product={selectedType.products} />
      </div>
    </div>
  )
}

export default Products;
